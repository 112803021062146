/**
 * Darken the given color by specified percent.
 * @param {string} color A hex color value to start from.
 * @param {number} percent Percent to shade the color.
 * @return A hex color value
 *
 * @link https://stackoverflow.com/a/13542669/8616016
 */
export const shadeColor = (color, percent) => {
  // Convert percent to a decimal.
  percent = percent / 100;

  var f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
};
