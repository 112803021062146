/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Global } from '@emotion/core';

import Header from './header';
import { FluidContainer } from './flex-container';
import heynodeLogo from '../assets/images/heynode-logo.svg';
import OsioLabsLogoSVG from '../assets/images/osio-labs-logo.svg';
import { shadeColor } from '../theme-utils';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Global
        styles={theme => ({
          body: {
            backgroundColor: theme.colors.background,
            fontFamily: theme.fonts.body,
            fontSize: 18,
            lineHeight: 1.5,
            margin: 0,
            padding: 0,
          },
          a: {
            color: theme.colors.primary,
            ':hover': {
              color: theme.colors.secondary,
            },
          },
        })}
      />
      <div

      >
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer
          sx={{
            backgroundColor: 'primary',
            borderTop: theme => `10px solid ${shadeColor(theme.colorsActual.primary, 75)}`,
            color: 'white',
            'a': {
              color: 'white',
            }
          }}
        >
          <FluidContainer
            sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              textAlign: 'left',
              padding: '4em',
              '> *': {
                margin: ['1em 0', '0 1em'],
              }
            }}
          >
            <a
              href="https://osiolabs.com"
              sx={{
                marginRight: '4em',
              }}
            >
              <img className="logo" src={OsioLabsLogoSVG} alt="Osio Labs, Inc." />
            </a>
            <p
              sx={{
                mx: [0, '2em'],
              }}
            >
              Previously Lullabot Education, we're now{' '}
              <a href="https://osiolabs.com">Osio Labs</a> &mdash; Open Source
              Inside and Out. We're on a mission to empower anyone to build
              websites using open source tools. We teach the technology and
              support the open source projects and communities that make it all
              possible.
            </p>
            <p>
              For over 10 years, we've been publishing tutorials, conducting
              workshops, supporting events, contributing code and documentation,
              and working in a variety of open source community leadership roles.
              Our site <a href="https://drupalize.me">Drupalize.Me</a> is the
              leading provider of Drupal training services.
            </p>
          </FluidContainer>
          <FluidContainer
            sx={{
              display: 'flex',
              textAlign: 'left',
              padding: '4em',
              '> *': {
                margin: ['1em 0', '0 1em'],
              }
            }}
          >
            <div>
              {new Date().getFullYear()} &copy; Osio Labs, Inc.
              {' '} &middot; {' '}
              <a href="https://heynode.com/blog">Blog</a>
              {' '} &middot; {' '}
              <a href="https://osiolabs.com/terms-conditions">Terms of use</a>
              {' '} &middot; {' '}
              <a href="https://osiolabs.com/privacy">Privacy</a>
            </div>
          </FluidContainer>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
