/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import heynodeLogo from '../assets/images/heynode-logo.svg';
import TwitterLogo from '../assets/images/twitter.inline.svg';
import { shadeColor } from '../theme-utils';

const iconStyles = {
  display: 'block',
  flex: '0 0 auto',
  margin: '0 0 0 0.5em',
  height: '25px',
  width: '25px',
  maxWidth: '25px',
  '& svg': {
    fill: theme => shadeColor(theme.colorsActual.text, 50),
    height: '100%',
    width: '100%',
    transition: 'fill .4s cubic-bezier(.55,.085,0,.99)',
  },
  '&:hover svg': {
    fill: 'primary',
  },
};

const Header = props => (
  <header
    {...props}
    sx={{
      backgroundColor: 'white',
      boxShadow: '0 .0625rem 1.25rem 0 rgba(0,0,0,.03)',
      margin: '0 0 2em 0',
    }}
  >
    <div
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        maxWidth: 'container',
        mx: 'auto',
        padding: '0.625em 2em 1.25em',
      }}
    >
      <a
        href="https://heynode.com/"
        sx={{
          color: 'text',
          display: 'flex',
          flex: 1,
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          padding: '1em 1em 0.5em 0',
          textAlign: 'left',
          textDecoration: 'none',
        }}
      >
        <img
          alt="Hey Node logo"
          src={heynodeLogo}
          sx={{
            marginRight: '1em',
          }}
        />
        <span>Expert Node.js training anytime, anywhere.</span>
      </a>
      <a
        href="https://twitter.com/heynode"
        title="Follow @heynode on Twitter"
        sx={iconStyles}
      >
        <TwitterLogo />
      </a>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
