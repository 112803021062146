/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

export const FixedContainer = props => (
  <div
    {...props}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 'container',
      mx: 'auto',
      px: 3,
    }}
  >
    {props.children}
  </div>
);

export const FluidContainer = props => (
  <div
    {...props}
    sx={{
      padding: '2em 1em',
      textAlign: 'center',
    }}
  >
    {props.children}
  </div>
);
